@import '../../var.scss';

.home {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .bottom-hero {
        height: 60vh;
        text-align: center;
        padding: 20px 0;

        img {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        }
    }

    .services-description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .button {
            margin: 30px 0;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .home {
        .bottom-hero {
            height: 32vh;
        }
    }
}