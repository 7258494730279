@import '../../var.scss';

.service-block {
    max-width: 700px;
    display: flex;
    margin: 0 15px;

    .icon {
        margin-right: 35px;
        margin-top: 25px;
        height: 65px;
        width: 65px;

        img {
            height: 100%;
            width: auto;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .service-block {
        flex-direction: column;
        margin-top: 0 15px;

        h2 {
            font-size: 23px;
            margin-top: 15px;
            margin-bottom: -5px;
        }

        .icon {
            height: 50px;
            width: 50px;
        }
    }
}