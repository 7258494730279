@import '../../var.scss';

.hiring-container {
  max-width: 700px;
  margin: 26px 15px 64px 15px;
  padding: 32px;
  gap: 12px;
  background-color: $nine;
  border-radius: 4px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.15);
}

.hiring-email {
  text-decoration: underline;
  font-weight: 600;
}
