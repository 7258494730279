.map-modal {
    height: 100vh;
    width: 100vw;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 40px;
        cursor: pointer;
    }

    .map-container {
        z-index: 3;
    }
}