@import '../../var.scss';

.mobile-menu {
    top: -100%;
    left: 0;
    background-color: $six;
    color: white;
    position: fixed;
    width: 100vw;
    display: flex;
    flex-direction: column;
    transition: all 1s;

    .menu-item {
        padding: 20px 15px;
        border-bottom: 2px solid #fafafa;
        color: white;
        display: flex;
        align-items: center;
        // justify-content: space-between;

        a {
            color: white;
            display: flex;
            align-items: center;

           
        }
        img {
            height: 30px;
            margin-right: 5px;
        }
    }


    i {
        font-size: 35px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        color: white;
        padding: 15px;
    }

}

.mobile-menu--open {
    top: 0;
}