@import '../../var.scss';

.services {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        max-width: 700px;
        margin: 20px auto 20px auto;
    }

    .service-blocks {
        display: flex;
        max-width: 1800px;
        margin: auto;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 48px;

        .service-block-img {
            max-width: 300px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
                border-radius: 4px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
            }
        }
    }

    .service-blocks:nth-child(odd) {
        .service-block-img{
            margin-left: 25px;
        }
    }

    .service-blocks:nth-child(even) {
        .service-block-img {
            margin-right: 25px;
        }
    }

}

@media only screen and (max-width: 1000px) {
    .services {
        .service-blocks {
            flex-direction: column;
            margin-top: 30px;
    
            .service-block-img {
                max-width: 200px;
                margin: 10px 0 0 10px;
            }
    
            .service-block {
        
                p {
                    font-size: 16px;
                }
            }
        }

        .service-blocks:nth-child(odd) {
            flex-direction: column-reverse;

            .service-block-img{
                margin-left: 10px;
            }
        }
    
        .service-blocks:nth-child(even) {
            .service-block-img {
                margin-right: 0;
            }
        }
    }
}