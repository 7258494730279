@import '../../var.scss';

.contact-banner {
    width: 90vw;
    max-width: 1200px;
    display: flex;
    background-color: $seven;
    justify-content: space-around;
    color: white;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin: auto;
    border-radius: 4px;

    .contact-section {
        max-width: 800px;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            height: 30px;
            width: auto;
        }

        h2 {
            margin: 10px;
            font-size: 18px;
        }
        ul {
            text-align: center;
    
            li {
                padding-bottom: 7px;
    
                span:first-child {
                    padding-right: 8px;
                }
            }
        }

        a {
            color: white;
        }
    }

}
