@import '../../var.scss';

.footer {
    background-color: $seven;
    padding: 20px 100px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footer-body {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        a {
            color: white;
        }
    }

    .footer-sub {
        font-size: 12px;
        padding-top: 50px;

        span, a {
            padding-right: 5px;
            color: white
        }
    }
}

@media only screen and (max-width: 1000px) {
    .footer {
        padding: 20px;

        .footer-sub {
            text-align: center;
            padding-top: 25px;
            font-size: 11px;
        }

        .footer-body {
            flex-direction: column;
            align-items: center;

            h3 {
                margin: 10px 0;
                font-size: 18px;
            }
        }
    }
}