.hours {  
    ul {
        text-align: center;

        li {
            padding-bottom: 7px;

            span:first-child {
                padding-right: 8px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .hours ul li {
        padding-bottom: 4px;
        font-size: 14px;
    }
}