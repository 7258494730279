@import '../../var.scss';

.contact {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .contact-banner-container {
        margin-top: -25px;
    }

    .map {
        width: 90vw;
        height: 60vh;
        text-align: center;
        margin: 80px auto;
    }

    .get-in-touch {
        font-size: 30px;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            margin: auto;
        }

        .success {
            background-color: green;
            border: green;
        }
    }

    .input-group {
        display: flex;
        flex-direction: column;

        max-width: 700px;
        width: 100%;
        margin: auto;
        justify-content: space-between;
        // margin-bottom: 25px;

        input, textarea {
            // width: 48%;
            padding: 10px 16px;
            border: 1px solid $darkgrey;
            border-radius: 3px;
            margin-bottom: 25px;
        }

        .message {
            width: 100%;
            height: 200px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .contact {
        .map {
            width: 95vw;
            height: 50vh;
        }

        .contact-banner-container {
            display: none;
        }

        .input-group {
            flex-direction: column;
            margin-bottom: 0;
            align-items: center;

            input, textarea {
                width: 90%;
                margin-bottom: 15px;
            }

            .message {
                width: 90%;
            }
        }

        .secondary-text {
            font-size: 25px;
        }
    }
}