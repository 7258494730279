@import './var.scss';

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
  color: $seven;
  font-weight: 400;
}

.secondary-text {
  // font-family: 'Libre Baskerville', serif;
  // font-family: 'Montserrat', sans-serif;
  color: $seven;
  font-weight: 400;
  letter-spacing: 1px;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

a {
  text-decoration: none;
  color: $seven;
}

ul {
  margin: 0;
  padding: 0;
} 

li {
  list-style: none;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  border: 1px solid $seven;
  background: $seven;
  padding: 15px 20px;
  text-align: center;
  transition: all -.15s;
  display: inline-block;
  color: white;
  letter-spacing: 1px;

  // font-family: 'Libre Baskerville', serif;
  // font-family: 'Abril Fatface', serif;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button:hover {
  opacity: 0.9;
}

::-webkit-input-placeholder {
  // font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
}
:-moz-placeholder {
  // font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
}
::-moz-placeholder {
  // font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
}
:-ms-input-placeholder {
  // font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

textarea, input {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}