.contact-info {
    img {
        height: 23px;
        width: 23px;
    }

    ul {
        text-align: center;

        li {
            padding-bottom: 7px;
        }

        li a, li div {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 7px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .contact-info {
        img {
            height: 18px;
            width: 18px;
        }

        ul li {
            padding-bottom: 4px;
            font-size: 14px;
        }
    }
}