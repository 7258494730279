header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 5px 64px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1;
    height: 80px;
    backdrop-filter: blur(10px);
}

.mobile-header {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: none;

    .logo {
        font-size: 22px;

        img {
            width: auto;
            height: 50px;
        }
    }

    i {
        font-size: 35px;
        cursor: pointer;
    }
}

.desktop-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    z-index: 1;

    .logo {
        font-size: 22px;

        img {
            width: auto;
            height: 60px;
        }
    }

    a {
        font-weight: 700;
    }

    nav a {
        text-transform: uppercase;
    }

    ul {
        display: flex;

        li {
            padding: 10px;
            display: flex;
            align-items: center;

            img {
                height: 23px;
                width: 23px;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .desktop-header {
        display: none;
    }

    .mobile-header {
        display: flex;
    }

    header {
        padding: 5px 24px;
        max-width: 1600px;

    }
}