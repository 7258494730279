@import '../../var.scss';

.about {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .about-block {
        display: flex;
        width: 75vw;
        margin: auto;
        justify-content: space-evenly;
        flex-direction: row-reverse;
        padding: 48px 0;

        .details {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px 0;
            line-height: 1.5;
            word-spacing: 1.2px;

            .title {
                font-style: italic;
                font-weight: 600;
                color: $six;
            }
            .name {
                font-size: 24px;
                font-weight: 600;
            }
        }

        .about-img {
            max-width: 350px;

            img {
                border-radius: 4px;
                max-width: 100%;
                height: auto;
                margin-bottom: -5px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
            }
        }
    }

    // .about-block:first-child {
    //     margin-top: -25px;
    //     padding: 0;
    // }

    .about-block:nth-child(odd) {
        flex-direction: row;

        .details {
            padding-left: 40px;
        }
    }

    .about-block:nth-child(even) {
        .details {
            padding-right: 40px;
        }
    }

    .about-block:last-child {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .about {
        .about-block {
            flex-direction: column;
            width: 100%;
            padding: 10px 20px;

            .details {
                width: 100%;
                padding: 20px 0;
            }

            .about-img {
                max-width: 150px;
            }
        }

        .about-block:first-child {
            margin-top: 0;
            padding: 10px 20px;
        }
        .about-block:nth-child(odd) {
            flex-direction: column;

            .details {
                padding-left: 0;
            }
        }

        .about-block:nth-child(even) {
            .details {
                padding-right: 0;
            }
        }
    }
}