$button: #403489;

$primary: #5D1D00;
$secondary: #7B3B1E;
$tertiary: #3F0A0A;
$darkgrey: #3C3C3C;

$one: #8da19d;
$two: #d0ddd5;
$three: #ecf5ee;
$four: #fafdfd;
$five: #829a9d;
$six: #2f565b;
$seven: #30353b;
$eight: #30353be5;
$nine: #F8FDFF;

